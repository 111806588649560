import React, { Component } from "react"
import {
  PDFViewer,
  Page,
  Text,
  View,
  Canvas,
  Document,
  Image,
  Font,
} from "@react-pdf/renderer"
import PriceTable from "./price-table"
import styles from "./price-list-styles"
import pdfImages from "./price-list-images"
const isBrowser = typeof window !== `undefined`

class PriceList extends Component {
  constructor(props) {
    super(props)
    let data = this.props.data.allMarkdownRemark.nodes
    //sort the data
    let priceData = this.sortPriceData(data)
    this.state = {
      priceData: priceData,
      hardwareKits: this.props?.context?.hardware_kits,
    }
  }

  componentDidUpdate(prevProps) {}

  sortPriceData = data => {
    data.sort((firstEl, secondEl) => {
      let firstName = firstEl.frontmatter.product_name
      let secondName = secondEl.frontmatter.product_name
      if (firstEl.frontmatter.sort_name) {
        firstName = firstEl.frontmatter.sort_name
      }
      if (secondEl.frontmatter.sort_name) {
        secondName = secondEl.frontmatter.sort_name
      }
      let returnVal = 0
      if (firstName < secondName) {
        returnVal = -1
      }
      if (firstName > secondName) {
        returnVal = 1
      }
      if (firstName === secondName) {
        returnVal = 0
      }
      return returnVal
    })
    return data
  }

  drawLine = (painter, w, h) => {
    painter.strokeColor("#000000")
    painter.moveTo(0, 0).lineTo(556, 0).stroke()
  }

  render() {
    if (isBrowser) {
      Font.register({
        family: "Helvetica Neue",
        src: "/fonts/HelveticaNeue-ThinCond.woff",
      })
      Font.register({
        family: "Helvetica Neue Bold",
        src: "/fonts/HelveticaNeue-Thin.woff",
      })
      Font.register({
        family: "Helvetica Neue Heavy",
        src: "/fonts/HelveticaNeue-HeavyExt.ttf",
      })
    }
    let pages = []
    let date = new Date()
    let header = (
      <View fixed style={styles.headerStyle}>
        <Image
          src={pdfImages.happyPlus.src}
          style={pdfImages.happyPlus.style}
        />
        <Text fixed style={styles.internalUseTitle}>
          FOR INTERNAL USE ONLY
        </Text>
        <Text fixed style={styles.priceListTitle}>
          {date.getFullYear()} STANDARD {this.props.title.toUpperCase()} PRICE
          LIST
        </Text>
        <Canvas paint={this.drawLine} style={styles.headerLineStyle} />
      </View>
    )

    let revDate =
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2) +
      "-" +
      date.getFullYear().toString().substr(-2)
    let footer = (
      <View fixed style={styles.footerStyle}>
        <Text
          style={styles.pageRevisions}
          fixed
          render={({ pageNumber, totalPages }) =>
            `Page ${pageNumber} of ${totalPages} | Rev. ${revDate}`
          }
        />
      </View>
    )

    let priceTables = this.state.priceData.map((product, i) => {
      return (
        <PriceTable
          product={product}
          key={i}
          wrap={false}
          hardwareKits={this.state.hardwareKits}
        />
      )
    })
    pages.push(
      <Page size="LETTER" style={styles.bodyStyle} key={pages.length + 1} wrap>
        {header}
        <View wrap>{priceTables}</View>
        {footer}
      </Page>
    )

    let renderPages = pages.map(function (object) {
      return object
    })
    let pdfViewer = (
      <PDFViewer
        style={{
          width: "100%",
          height: "calc(100vh - 100px)",
        }}
      >
        <Document>{renderPages}</Document>
      </PDFViewer>
    )
    if (!isBrowser) {
      pdfViewer = null
    }
    return <>{pdfViewer}</>
  }
}

export default PriceList
